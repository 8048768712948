<template>
  <Drawer class="drawer_box" :title="$t('key1000647')" v-model="customColumnsModal" width="260" :mask-closable="false" :closable="false">
    <Table
      draggable
      ref="selection"
      :show-header="false"
      :highlight-row="false"
      :columns="tableColumns"
      :disabled-hover="true"
      :data="tableData"
      :max-height="screenHeight - 108"
      @on-drag-drop="dragDrop"
      @on-select-cancel="selectCancel"
      @on-selection-change="tableChange">
    </Table>
    <div class="drawer_footer">
      <Button class="mr15" @click="customColumnsModal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" @click="customColumnsDrawerBtn" :disabled="customColumnsTalg">{{ $t('key1000367') }}</Button>
    </div>
  </Drawer>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: 'customColumnsDrawer',
  mixins: [Mixin],
  props: {
    customColumnsKey: { // 自定义列的key
      type: String,
      default: null
    },
    defaultSelectKeyList: { // 自定义列--默认选中的key
      type: Array,
      default: () => []
    },
    originalColumns: { // 列表原始的字段数据
      type: Array,
      default: () => []
    },
    isDelay: { // 是否延迟加载
      type: Boolean,
      default: false
    },
    customSorting: { // 默认展示的字段支持自定义排序
      type: Boolean,
      default: false
    },
    fixedColumnkeyList: { // 固定列，不参与自定义排序
      type: Array,
      default: () => []
    }
  },
  data() {
    let v = this;
    return {
      customColumnsModal: false,
      tableColumns: [
        {
          type: 'selection',
          width: 40,
          align: 'center',
          key: 'select'
        },
        {
          align: 'left',
          key: 'title',
          minWidth: 160,
        },
        {
          align: 'center',
          width: 50,
          key: 'sort',
          render(h) {
            let color = v.system === 'distributor' ? '#FD5425' : '#2D8CF0';
            return h('div', {
              class: 'flex align-items-center justify-content-end',
              style: {
                cursor: 'move',
              }
            }, [
              h('Icon', {
                props: {
                  type: 'ios-apps',
                  size: 16
                }
              }),
              h('div', {
                style: {
                  height: '48px',
                  width: '4px',
                  backgroundColor: color,
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                  marginLeft: '10px'

                }
              })
            ])
          }
        },
      ],
      tableData: [],
      selectTableData: [],
      screenHeight: 0,
      customColumnsTalg: false,
    }
  },
  mounted() {
    this.screenHeight = window.innerHeight;
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  created() {
    if (!this.isDelay) {
      this.getFieldsList('update');
    }
  },
  methods: {
    // 监听屏幕的可视高度
    handleResize() {
      this.screenHeight = window.innerHeight;
    },
    // 初始化数据
    initCustomColumnsData() {
      let v = this;
      let newList = [];
      v.selectTableData = [];
      let selectData = [];
      let columnsList = [];
      if (v.customSorting) {
        columnsList = v.originalColumns.filter((item) => {
          return !v.fixedColumnkeyList.includes(item.key)
        });
      } else {
        columnsList = v.originalColumns.filter((item) => {
          return !v.defaultSelectKeyList.includes(item.key)
        });
      }
      v.getFieldsList().then((setColumnsList) => {
        if (columnsList.length > 0) {
          columnsList.map((item) => {
            newList.push({
              title: item.title,
              key: item.key,
              _checked: setColumnsList.length <= 0,
              _disabled: v.customSorting && v.defaultSelectKeyList.includes(item.key)
            })
          });
          if (newList.length > 0) {
            if (setColumnsList.length > 0) {
              newList.map((item) => {
                if (setColumnsList.includes(item.key)) {
                  item._checked = true;
                  selectData.push(item);
                } else {
                  if (item._disabled) {
                    item._checked = true;
                    selectData.push(item);
                  }
                }
              });
              if (selectData.length >0) {
                let list = selectData.sort((a, b) => setColumnsList.indexOf(a.key) - setColumnsList.indexOf(b.key));
                v.selectTableData = v.uniqueFunc(list, 'key');
              }
            }
            let arr = newList.sort((a, b) => setColumnsList.indexOf(a.key) - setColumnsList.indexOf(b.key));
            v.tableData = arr.sort(function (a, b) {
              // 将_checked属性值为true的项排列在最前面
              if (a._checked && !b._checked) {
                return -1;
              } else if (!a._checked && b._checked) {
                return 1;
              }
            });

            if (setColumnsList.length <= 0) {
              // 没有设置过的，默认全部选中
              v.tableChange(v.tableData);
            }
          }
        }
      });
      v.customColumnsModal = true;
    },
    // 获取列表自定义的字段
    getFieldsList(type) {
      let v = this;
      v.customColumnsTalg = false;
      let list = [];
      return new Promise((resolve) => {
        let query = {
          paramKeys: [v.customColumnsKey],
          businessId: v.userInfo.userId
        }
        v.axios.post(api.post_erpCommonSettingParam_queryByUK, query).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              data.map((item) => {
                if (item.paramKey === v.customColumnsKey) {
                  if (item.paramValue) {
                    let obj = JSON.parse(item.paramValue);
                    list = obj[v.customColumnsKey] || [];
                    if (type === 'update') {
                      v.$emit('customColumnsData', list)
                    }
                    resolve(list);
                  }
                }
              })
            } else {
              if (type === 'update') {
                v.$emit('customColumnsData', list)
              }
              resolve(list);
            }
          }
        });
      });
    },
    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = [];
      if (data.length > 0) {
        data.map((item) => {
          item._checked = true;
        });
        this.selectTableData = data;
      }
    },
    // 取消选中的数据
    selectCancel(selection, row) {
      let v = this;
      v.tableData.map((item, index) => {
        if (item.key === row.key) {
          this.$set(this.tableData[index], '_checked', false);
          this.$forceUpdate();
        }
      })
    },
    // 优先级拖拽排序
    dragDrop(startIndex, endIndex) {
      let newSortList = [];
      let v = this;
      v.customColumnsTalg = true;
      const [movedItem] = v.tableData.splice(startIndex, 1)
      v.tableData.splice(endIndex, 0, movedItem);
      if (v.selectTableData.length > 0) {
        v.tableData.map((item, index) => {
          v.selectTableData.map((ele) => {
            if (item.key === ele.key) {
              v.$set(v.tableData[index], '_checked', true);
              ele['_checked'] = true;
              newSortList.push(ele);
              v.$forceUpdate();
            }
          })
        })
      }
      setTimeout(() => {
        v.selectTableData = newSortList;
        v.customColumnsTalg = false;
      }, 300);
    },
    // 保存
    customColumnsDrawerBtn() {
      let v = this;
      let paramValueList = {};
      if (v.selectTableData.length > 0) {
        paramValueList[v.customColumnsKey] = v.selectTableData.map((item) => {
          return item.key
        });
        let query = [
          {
            paramKey: v.customColumnsKey,
            paramValue: JSON.stringify(paramValueList),
            businessId: v.userInfo.userId
          }
        ];
        v.axios.post(api.post_erpCommonSettingParam_saveList, query, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(res => {
          if (res.data.code === 0) {
            v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000102'));
            v.getFieldsList('update');
            v.customColumnsModal = false;
          }
        });
      } else {
        v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1000817'));
        return false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.drawer_box {
  /deep/ .ivu-drawer-wrap {
    z-index: 9991;
  }

  /deep/ .ivu-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;

    .ivu-table .ivu-table-tbody {

      .ivu-table-cell {
        padding: 0;
      }
    }
  }

  .drawer_footer {
    border-top: 1px solid #e8eaec;
    text-align: right;
    padding: 12px 15px 12px 0;
  }
}
</style>