<template>
  <Modal v-model="modal" width="1000" :title="$t('key1000619')">
    <div v-if="recentlyUsed && recentlyUsed.length" class="mb20">
      <span>{{ $t('key1000843') }}</span>
      <span class="last-history" v-for="category in recentlyUsed" @click="choseRecently(category)">{{ category.productCategoryNavigation }}</span>
    </div>
    <vueTreeSelect
      style="width: 400px;"
      v-model="ymsProductCategoryId"
      :clearable="true"
      :searchable="true"
      :openOnFocus="true"
      :closeOnSelect="true"
      :noResultsText="$t('key1000087')"
      :noOptionsText="$t('key1000841')"
      :placeholder="$t('key1000086')"
      :normalizer="normalizer"
      @input="changeYmsProductCategory"
      @select="selectYmsProductCategory"
      :options="ymsProductCategoryList">
      <template #option-label="{ node, labelClassName }">
        <label
          :class="labelClassName"
          :title="node.label">
          {{ node.label }}
        </label>
      </template>

    </vueTreeSelect>
    <Spin size="large" fix v-if="spinShow"></Spin>
    <Row class="mt20" :gutter="10">
      <!--判断是否有大类-->
      <Col span="8" class="category-item-box" v-if="isMultiSpecificationAttributeMerchant">
        <Input class="search-ipt" v-model="treeSearch" :placeholder="$t('key1000842')" @on-change="changeTreeSearch" clearable/>
        <Tree class="category-tree-render" :render="renderContent" :data="treePartData"></Tree>
      </Col>
      <Col span="8" class="category-item-box" v-for="(category,categoryIndex) in categoryItemData">
        <div class="search-ipt">
          <Input :placeholder="$t('key1000842')" @on-change="changeCategoryItem($event,categoryIndex)" clearable/>
        </div>
        <div v-for="item in category" :ref="'categoryBox'+categoryIndex">

          <div class="category-child-item" v-if="!item._hide" :class="{activeItem:item.active}" @click="setNextChild(item,category)">
            <span class="text">{{ item.title }}</span>
            <span class="mr10" v-if="item.children && item.children.length">></span>
          </div>
        </div>
      </Col>
    </Row>
    <p class="mt10">{{ $t('key1000844') }}{{ choseCategoryObj.productCategoryNavigation }}</p>
    <template #footer>
      <Button @click="modal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" :disabled="!isLeaf" @click="confirm">{{ $t('key1000096') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/index';
import vueTreeSelect from '@riophae/vue-treeselect';
import {commonSessionStorage, getYmsSiteData} from "@/utils/common";

export default {
  name: 'productCategory',
  mixins: [Mixin],
  props: {
    categoryList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modal: false,
      ymsProductCategoryId: null,
      spinShow: false,
      ymsProductCategoryList: [], // 云卖分类下拉框的数据
      data2: [
        {
          title: 'parent 1',
          expand: true,
          children: [
            {
              title: 'parent 1-1',
              expand: true,
              children: [
                {
                  title: 'leaf 1-1-1'
                },
                {
                  title: 'leaf 1-1-2'
                }
              ]
            },
            {
              title: 'parent 1-2',
              expand: true,
              children: [
                {
                  title: 'leaf 1-2-1'
                },
                {
                  title: 'leaf 1-2-1'
                }
              ]
            }
          ]
        }
      ],
      treeData: [],
      choseCategoryObj: {},
      treePartData: [],
      categoryItem: [],
      recentlyUsed: [],
      treeSearch: '',
      isLeaf: false,
      optionsType: '',
    };
  },
  components: {
    vueTreeSelect
  },
  methods: {
    open(choseCategoryObj, type) {
      this.choseCategoryObj = {};
      this.optionsType = type;
      this.getRecentlyUsed();
      this.getCloudSalesClassification().then(() => {
        if (choseCategoryObj && choseCategoryObj.productCategoryNavigation) {
          this.choseCategoryObj = choseCategoryObj;
          this.selectYmsProductCategory(choseCategoryObj)
        }
      });
      this.modal = true;
    },
    choseRecently(category) {
      this.choseCategoryObj = category;
      this.selectYmsProductCategory(category)

    },
    // 修改类目确定按钮
    confirm() {
      let v = this;
      if (v.choseCategoryObj) {
        if (v.optionsType === 'modify') {
          v.$Modal.confirm({
            title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000845'),
            content: alias1bddc4d174174d47a6dfb0437dace856.t('key1000846'),
            okText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000847'),
            cancelText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000097'),
            onOk: () => {
              handleData();
            },
            onCancel: () => {
            }
          });
        } else {
          handleData();
        }
      } else {
        v.$Message.info(alias1bddc4d174174d47a6dfb0437dace856.t('key1000848'));
      }

      function handleData() {
        v.setRecentlyUsed(v.choseCategoryObj);
        v.$emit('saveCategory', v.choseCategoryObj);
        v.modal = false;
      }
    },
    changeTreeSearch() {
      let value = this.treeSearch;
      let v = this;
      v.treePartData = (JSON.parse(JSON.stringify(v.treeData))).map(i => {
        if (i.children) {
          if (value) {
            i.expand = true;
          }
          i.children = i.children.filter(k => {
            if (k.children && k.children.length) {
              k.hasChildren = true;
            }
            k._children = JSON.parse(JSON.stringify(k.children));
            k.children = null;
            return k.productCategoryNavigation.includes(value);
          });
        }
        return i;
      }).filter(i => {
        let _has = false;
        if (i.productCategoryNavigation.includes(value)) {
          _has = true;
        }
        if (i.children && i.children.length) {
          _has = true;
        }
        return _has;
      });
      /*v.$nextTick(()=>{
        let dom = document.querySelectorAll('.category-title');
        for(let i=0;i<dom.length;i++) {
          if (dom[i].innerText.includes(value) && value) {
            dom[i].innerHTML = dom[i].innerText.split(value).join('<b class="active-font">'+value+'</b>');
          } else {
            dom[i].innerHTML = dom[i].innerText;
          }
        }
      });*/
    },
    changeCategoryItem(e, index) {
      let value = e.target.value;
      this.categoryItem[index].forEach(i => {
        this.$set(i, '_hide', !i.title.includes(value) && value);
      });

      this.$nextTick(() => {
        let dom = this.$refs['categoryBox' + index];
        for (let i = 0; i < dom.length; i++) {
          if (dom[i].children[0]) {
            if (dom[i].children[0].childNodes[0].innerText.includes(value)) {
              dom[i].children[0].childNodes[0].innerHTML = dom[i].children[0].childNodes[0].innerText.split(value).join('<b class="active-font">' + value + '</b>');
            } else {
              dom[i].children[0].childNodes[0].innerHTML = dom[i].children[0].childNodes[0].innerText;
            }
          }
        }
      });
    },
    getRecentlyUsed() {
      if (localStorage.getItem('categoryRecentlyUsed')) {
        this.recentlyUsed = JSON.parse(localStorage.getItem('categoryRecentlyUsed')).slice(0, 3);
      } else {
        this.recentlyUsed = [];
      }
    },
    setRecentlyUsed(data) {
      this.recentlyUsed.forEach((i, index) => {
        if (i.productCategoryNavigation === data.productCategoryNavigation) {
          this.recentlyUsed.splice(index, 1);
        }
      });
      this.recentlyUsed.unshift(data);
      localStorage.setItem('categoryRecentlyUsed', JSON.stringify(this.recentlyUsed));
    },
    setNextChild(item, category) {
      this.choseCategoryObj = {};
      let index = item.productCategoryNavigation.split('->').length;
      category.forEach(i => {
        i.active = false;
      });
      item.active = true;
      this.categoryItem.splice(index, this.categoryItem.length - index);
      if (item.children && item.children.length) {
        item.children.forEach(i => {
          i.active = false;
        });
        this.categoryItem.push(item.children);
      }
      this.choseCategoryObj = item;
      this.isLeaf = !(item.children && item.children.length);

    },
    // 整理商品分类数据
    toTreeData(data) {
      this.treeData = [];
      this.categoryItem = [];
      data = data.sort(this.sortCategory);
      let newArr = this.handlerClassifyData(data, 'productCategoryNavigation', '->', false);
      this.treeData = newArr;
      if (!this.isMultiSpecificationAttributeMerchant) {
        this.categoryItem.push(newArr);
      }
      this.treePartData = (JSON.parse(JSON.stringify(newArr))).map(i => {
        if (i.children) {
          i.children.forEach(k => {
            if (k.children && k.children.length) {
              k.hasChildren = true;
            }
            k._children = JSON.parse(JSON.stringify(k.children));
            k.children = null;
          });
        }
        return i;
      });
    },
    renderContent(h, {data}) {
      let index = data.productCategoryNavigation.split('->').length;
      let v = this;
      return h('div', {
        style: {
          display: 'flex',
          width: '92%',
          justifyContent: 'space-between',
          justifyItems: 'center'
        },
        on: {
          'click'() {
            v.choseCategoryObj = data;
            v.categoryItem = [];
            if (!data.children || data.children.length === 0) {
              v.isLeaf = true;
            } else {
              v.isLeaf = false;
            }
            if (index === 2 && data.hasChildren) {
              v.treeData.forEach(i => {
                if (i.children) {
                  v.isLeaf = false;
                  i.children.forEach(k => {
                    if (k.productCategoryNavigation === data.productCategoryNavigation) {
                      v.categoryItem.push(k.children);
                    }
                  });
                }
              });
            }
          }
        }
      }, [
        h('span', {class: 'category-title'}, data.title),
        index === 2 && data.hasChildren ? h('span', '>') : ''
      ]);
    },
    changeYmsProductCategory(ymsProductCategoryId) {

    },
    // 设置分组内容
    setGroupValue(productCategoryNavigation) {
      let _arr = productCategoryNavigation.split('->');
      let v = this;
      if (this.isMultiSpecificationAttributeMerchant) {
        v.categoryItem = [];

        this.treePartData.forEach((i, index) => {
          this.$set(this.treePartData[index], 'selected', false);
          this.$set(this.treePartData[index], 'expand', false);
          if (i.title === _arr[0]) {
            this.$set(this.treePartData[index], 'selected', true);
            this.$set(this.treePartData[index], 'expand', true);

            if (i.children && i.children.length && _arr.length > 1) {
              i.children.forEach((k, kIndex) => {
                this.$set(this.treePartData[index], 'selected', false);
                this.$set(this.treePartData[index].children[kIndex], 'selected', false);

                if (k.title === _arr[1]) {
                  this.$set(this.treePartData[index].children[kIndex], 'selected', true);
                  if (k._children && k._children.length) {
                    v.categoryItem.push(k._children);
                  } else {
                    v.isLeaf = true;
                  }
                }
              });
            }
          }
        });
        if (v.categoryItem && v.categoryItem.length > 0) {
          _arr.slice(2,).forEach((i, index) => {
            if (v.categoryItem[index]) {
              v.categoryItem[index].forEach(k => {
                k.active = false;
                if (k.title === i) {
                  k.active = true;
                  v.categoryItem.splice(index + 1, v.categoryItem.length - index);
                  if (k.children && k.children.length) {
                    v.categoryItem.push(k.children);
                  } else {
                    v.isLeaf = true;
                  }
                }
              });
            }
          });
        }
      } else {
        _arr.forEach((i, index) => {
          if (v.categoryItem[index]) {
            v.categoryItem[index].forEach(k => {
              k.active = false;
              if (k.title === i) {
                k.active = true;
                v.categoryItem.splice(index + 1, v.categoryItem.length - index);
                if (k.children && k.children.length) {
                  v.isLeaf = false;
                  v.categoryItem.push(k.children.map(i => {
                    i.active = false;
                    return i;
                  }));
                } else {
                  v.isLeaf = true;
                }
              }
            });
          }
        });
      }
    },
    // 选取云卖分类
    selectYmsProductCategory(data) {
      if (data) {
        this.setGroupValue(data.productCategoryNavigation);
        this.choseCategoryObj = data;
      }
    },
    // 获取云卖分类的数据
    getCloudSalesClassification() {
      let v = this;
      let url = v.system === 'yms' ? api.post_ymsProductCategory_queryAll : api.post_ymsProductCategory_api_queryAll;
      v.spinShow = true;
      let list = [];
      let ymsSiteId = null;
      return new Promise(resolve => {
        let obj = commonSessionStorage.getItem('erpConfig');
        if (v.system === 'yms') {
          getYmsSiteData().then((data) => {
            if (data.length > 0) {
              let query = v.$route.query;
              if (query.platformId === 'alibabagj') {
                list = data.filter((item) => {
                  return item.merchantType === 'ALIGJ'
                })
              } else {
                list = data.filter((item) => {
                  return item.merchantType === 'YMS'
                });
              }
              ymsSiteId = list[0].ymsSiteId;
              handleData(ymsSiteId)
            }
          });
        } else {
          ymsSiteId = obj ? obj.ymsSite ? obj.ymsSite.ymsSiteId : null : null;
          handleData(ymsSiteId)
        }

        function handleData() {
          if (v.categoryList.length > 0) {
            let arr = v.handlerClassifySort(v.categoryList);
            arr.map((item) => {
              item.title = item.productCategoryNavigation;
            });
            v.spinShow = false;
            v.ymsProductCategoryList = arr;
            v.toTreeData(JSON.parse(JSON.stringify(arr)));
            resolve();
          } else {
            v.getCloudTreeData(url, 'tile', null, ymsSiteId).then((data) => {
              v.spinShow = false;
              v.ymsProductCategoryList = data;
              v.toTreeData(JSON.parse(JSON.stringify(data)));
              resolve();
            });
          }
        }
      });
    }
  },
  computed: {
    //  是否为睿栀分销系统
    isMultiSpecificationAttributeMerchant() {
      if (this.$store.state.erpConfig.isMultiSpecificationAttributeMerchant) {
        return this.$store.state.erpConfig.isMultiSpecificationAttributeMerchant === 'Y';
      } else {
        return false;
      }
    },
    categoryItemData() {
      let len = 3;
      if (this.isMultiSpecificationAttributeMerchant) {
        len = 2;
      }
      if (this.categoryItem.length < 3) {
        for (let i = 0; i < len; i++) {
          if (!this.categoryItem[i]) {
            this.categoryItem.push({});
          }
        }
      }
      return this.categoryItem;
    }
  }
};
</script>
<style>
.category-tree-render .ivu-tree-title {
  width: 100%;
}

.category-tree-render .ivu-tree-title-selected, .category-tree-render .ivu-tree-title-selected:hover {
  color: #008cff;
}

.active-font {
  color: #008cff !important;
  font-weight: initial;
}
</style>

<style lang="less" scoped>
.mti10 {
  margin-bottom: 10px !important;
}

.category-item-box {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #ddd;
  padding-bottom: 20px;

  .search-ipt {
    position: sticky;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    background-color: #fff
  }
}

.category-child-item {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  line-height: 21px;
  width: 100%;
  margin: 8px 0;

  &:hover {
    background-color: #EAF4FE;
  }

  .text {
    display: inline-block;
    width: 95%;

  }

}

.activeItem {
  background-color: #EAF4FE;
  color: #008cff;
}

.last-history {
  display: inline-block;
  border: 1px solid #d9d9d9;
  padding: 2px 10px;
  cursor: pointer;
  transition: border-color 0.1s ease-in;
  margin-right: 10px;

  &:hover {
    border-color: #c3c3c3;
    color: #333;
    border-radius: 1px;
  }
}
</style>
