<template>
  <Modal v-model="reasonForRejectionModal" :title="$t('key1000412')" width="550"
    :mask-closable="false" @on-visible-change="reasonForRejectionChange">
    <Input v-model.trim="rejectText" type="textarea" :rows="6"></Input>
    <template #footer>
      <Button @click="reasonForRejectionModal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" @click="reasonForRejectionBtn">{{ $t('key1000096') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';

export default {
  name: 'reasonForRejectionModal',
  mixins: [Mixin],
  data() {
    return {
      reasonForRejectionModal: false,
      rejectText: '',
      ymsProductInfoTemplateIds: []
    }
  },
  methods: {
    // 初始化数据
    initReasonForRejectionData(ymsProductInfoTemplateIds) {
      this.ymsProductInfoTemplateIds = ymsProductInfoTemplateIds;
      this.reasonForRejectionModal = true;
    },
    // 确定
    reasonForRejectionBtn() {
      let query = {
        shelvedRejectReason: this.rejectText,
        ymsProductInfoTemplateIds: this.ymsProductInfoTemplateIds
      };
      this.axios.put(api.put_ymsProductInfoTemplate_batchRejected, query, {
        loading: true,
        loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')
      }).then(response => {
        if (response.data.code === 0) {
          this.reasonForRejectionModal = false;
          this.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000084'));
          if (this.$route.path !== '/approvalList') {
            this.$router.push('/approvalList');
          } else {
            this.$emit('updateData', true);
          }
        }
      });
    },
    // 监听弹窗
    reasonForRejectionChange(value) {
      if (!value) {
        this.rejectText = '';
        this.ymsProductInfoTemplateIds = [];
      }
    }
  }
};
</script>

<style lang="less">

</style>
